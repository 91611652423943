// THIS FILE IS GENERATED BY 'npm run set-env-variables'

export const environment = {
  production: true,

  // Configuration/Feature-switches:
  envName: '',
  useServiceWorker: true,
  useOffers: true,
  useQandAs: true,
  useQandASearch: true,
  useRegionPerLocale: false,
  useFeedbackPrompt: false,

  // Configuration of content
  localeLanguage: 'en',
  localeDir: 'ltr',
  localeAlternatives: 'ar:العربية, en:English, es:Español, fr:Français, *:Other...',
  localeAlternativesExplanation: '* Translations via Google Translate',
  appName: 'IDM 2024 briefing',
  appLogoUrl: 'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_the_Red_Cross.svg',
  mainPageHeader: 'IDM 2024 briefing',
  mainPageIntroduction: 'This Helpful Information web-App (HIA) is made to brief you for and answer your frequently asked questions about the NLRC IDM 2024 (30 September - 4 October).   \n \n Please click on the button below to access all information.',
  mainPageNotification: '',
  pageAbout: '\n',
  pagePrivacy: '\n',
  mainFooter: '**Helpful Information**  \n \n',
  errorHeader: 'Data Unavailable',
  errorMessage: 'Reach out to us at: ',
  errorContactUrl: 'https://github.com/rodekruis/helpfulinformation-idm2024',
  errorRetry: 'Try again?',

  // Regions:
  regionConfig: '',
  regions: 'info',
  regionsLabels: 'Information',
  regionsSheetIds: '1l43SgRymj3EPBOd-wp3mWrfcqDZlKbuAy7HQGo8YnT8',

  // Third-party tokens:
  // Google Sheets API:
  google_sheets_api_key: 'AIzaSyDTYKoBzCmUbI8pExr3eo0OY_2hTdJ0V0k',
  google_sheets_api_url: 'https://sheets.googleapis.com/v4/spreadsheets',

  // Application Insights
  appInsightsConnectionString: '',
};
